import { Box, Icon, Link, Text } from '@chakra-ui/react'
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import KachLogo from 'components/KachLogo'
import React from 'react'

function Page404() {
  return (
    <Box
      display='flex'
      flexDir='column'
      justifyContent='center'
      alignItems='center'
      h='100vh'
    >
      <KachLogo size='4rem' variant='primary' />
      <Box
        mt={12}
        display='flex'
        flexDir='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
      >
        <Text color='primary' fontWeight='bold' fontSize='xl'>
          404
        </Text>
        <Box>
          <Text fontWeight='bold' fontSize='4xl'>
            Página no encontrada
          </Text>
          <Text color='gray.500'>
            Lo sentimos, no hemos podido encontrar la página que busca.
          </Text>
        </Box>
        <Link mt={12} href='/'>
          Volver al inicio <Icon as={ArrowLongRightIcon} />
        </Link>
      </Box>
    </Box>
  )
}

export default Page404
